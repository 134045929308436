import { SET_WINDOW_SIZE, SET_WINDOW_SCROLL } from "../constants";

export function setWindowSize(payload) {
  return {
    type: SET_WINDOW_SIZE,
    payload
  };
}

export function setWindowScrollTop(payload) {
  return {
    type: SET_WINDOW_SCROLL,
    payload
  };
}
