import React, { Component, lazy, Suspense } from "react";
import { withRouter } from "react-router";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";

import * as actions from "../../actions/app";
import { ClipLoader } from "react-spinners";
import Chat from "../Chat";

const Home = lazy(() => import("../Home"));
const Order = lazy(() => import("../Order"));
const About = lazy(() => import("../About"));
const Footer = lazy(() => import("../Footer"));

const Checker = props => {
	let {
		location: { search }
	} = props;
	if (search) {
		let items = queryString.parse(search);
		if (items) {
			if (items.utm_source) {
				localStorage.setItem("utm_source", items.utm_source);
			}
			if (items.utm_medium) {
				localStorage.setItem("utm_medium", items.utm_medium);
			}
		}
	}

	return null;
};

const CheckerWithRouter = withRouter(Checker);

export class AppRouter extends Component {
	constructor(props) {
		super(props);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.updateWindowScrollTop = this.updateWindowScrollTop.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener("resize", this.updateWindowDimensions);
		window.addEventListener("scroll", this.updateWindowScrollTop);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
		window.removeEventListener("scroll", this.updateWindowScrollTop);
	}

	updateWindowDimensions() {
		this.props.setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		});
	}

	updateWindowScrollTop() {
		try {
			this.props.setWindowScrollTop({
				scrollTop: window.scrollY
			});
		} catch (error) {}
	}

	render() {
		return (
			<Router>
				<Suspense
					fallback={
						<div
							className="sweet-loading"
							style={{
								height: "100vh",
								width: "100vw",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<ClipLoader color={"#f79563"} loading />
						</div>
					}
				>
					<div>
						<div>
							<Route exact path="/" component={Home} />
							<Route path="/Order" component={Order} />
							<Route path="/About" component={About} />
						</div>
						<Footer ref={el => (this.footer = el)} />
						<CheckerWithRouter />
					</div>
				</Suspense>
				<Chat />
			</Router>
		);
	}
}

const mapStateToProps = ({ app }) => ({
	app
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
