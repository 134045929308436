import { SET_WINDOW_SIZE, SET_WINDOW_SCROLL } from "../constants";

const initialState = {
  height: 0,
  width: 0,
  scrollTop: 0
};

export default function(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case SET_WINDOW_SIZE:
      return { ...state, height: payload.height, width: payload.width };
    case SET_WINDOW_SCROLL:
      return { ...state, scrollTop: payload.scrollTop };
    default:
      return state;
  }
}
