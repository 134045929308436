var api = "";
if (process.env.REACT_APP_API) {
	api = process.env.REACT_APP_API;
} else if (process.env.NODE_ENV === "development") {
	api = "";
} else {
	api =
		window.location.protocol.indexOf("https") > "-1"
			? "https://api.emkasa.com.br"
			: "http://api.emkasa.com.br";
}

api = "https://api.emkasa.com.br";

export const url = api;

export function createRequest(url, method, body, headers) {
	let options = {
		method,
		headers: {
			"content-type": "application/json",
			...headers
		}
	};

	if (body) options.body = JSON.stringify(body);

	url = api + url;

	return fetch(url, options);
}

export function sendPost(url, body, headers) {
	return createRequest(url, "POST", body, headers);
}

export function sendPut(url, body, headers) {
	return createRequest(url, "PUT", body, headers);
}

export function sendGet(url, headers) {
	return createRequest(url, "GET", null, headers);
}

export function sendDelete(url, headers) {
	return createRequest(url, "DELETE", null, headers);
}
