import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";
import "react-dates/initialize";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import "./App.css";

import AppRouter from "./components/AppRouter";

import reducers from "./reducers";

const store = createStore(reducers);

if (process.env.REACT_APP_LOG_ROCKET) {
  setupLogRocketReact(LogRocket);
  
	LogRocket.init(process.env.REACT_APP_LOG_ROCKET, {
		network: {
			requestSanitizer: request => {
				if (request.body && request.body.indexOf("password") > -1) {
					let body = JSON.parse(request.body);

					if (body.password) body.password = null;

					request.body = JSON.stringify(body);
				}

				// request.headers["Authorization"] = null;

				return request;
			}
		}
	});
}

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<AppRouter />
			</Provider>
		);
	}
}

export default App;
