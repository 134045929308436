const strings = {
	brand: "emKasa",
	homeTitle: "Nós temos o que você precisa para se sentir emKasa!",
	aboutUs: "Quem somos",
	partnerButton: "Corretor, seja um parceiro emKasa",
	rentalTitleMessage: "Aluguel de roupa de cama e banho para temporada",
	city: "Cidade",
	guests: "Hóspedes",
	chooseProducts: "Escolher Produtos",
	arrival: "Chegada",
	output: "Saída",
	reserveOnlinePopoverTitle: "Reserve online e garanta seu desconto",
	howTheRentalWorksPopoverTitle: "Como funciona a locação",
	ourServicesPopoverTitle: "Nossos serviços",
	ourProductsPopoverTitle: "Nossos produtos",
	ourMachinesPopoverTitle: "Nossas máquinas",
	reserveOnlinePopoverLine01:
		"A partir de 7 diárias, ganhe 10% de desconto nos produtos",
	reserveOnlinePopoverLine02:
		"A partir de 10 diárias, ganhe 15% de desconto nos produtos",
	reserveOnlinePopoverLine03:
		"A partir de 15 diárias, entre em contato conosco pelo chat ou telefone",
	howTheRentalWorksPopoverLine01:
		"Por meio de um sistema de locação, a emKasa disponibiliza lençóis de todos os tamanhos, fronhas, mantas, toalhas de rosto e de banho e toalha de piso.",
	howTheRentalWorksPopoverLine02:
		"Todos os nossos produtos são de alto padrão de qualidade, Tudo higienizado e com aquele cheirinho de aconchego!",
	ourServicesPopoverLine01: "Locação de enxoval",
	ourServicesPopoverLine02: "Lavanderia Self + Lounge",
	ourServicesPopoverLine03: "Coworking",
	ourServicesPopoverLine04: "Impressão",
	ourServicesPopoverLine05: "Internet e Games",
	ourProductsPopoverLine01:
		"A emkasa conta com o que há de melhor no mercado em relação à qualidade hoteleira, nosso enxoval de cama e banho são da linha Hoteltex da Altenburg. As toalhas são um banho de charme, que se destacam pela qualidade do fio retorcido, resultando em beleza e funcionalidade para valorizar um dos momentos mais relaxantes do dia.  Já os lençóis, fazem um convite para o sono, com seus 180 fios, sendo 50% algodão e 50% poliéster.  Todos altamente higienizados, e com aquele cheirinho de conforto que você merece.",
	ourMachinesPopoverLine01:
		"Para higienizar as roupas de cama e banho e deixar com aquele toque de macies e aconchego que você merece a emKasa utiliza equipamentos com Tecnologia Maltec. Equipamentos que podem realizar lavagens em nível de alta sujidade com processos extremamente eficientes, com um consumo reduzido de água, produto químico e energia elétrica o que torna nosso serviço  amigo do meio ambiente.",
	laundryTitle: "A Lavanderia Self",
	laundrySubtitle: "Lavanderia + Lounge + Coworking",
	priceTable: "Tabela de preços",
	justDry: "Só secar",
	justWash: "Só lavar",
	weDoItForYou: "Nós fazemos por você",
	specialPricesForStudentsMessage:
		"preços especiais para estudantes, mensalistas e promoções... consulte-nos.",
	happyHourTitle: "Transforme o trabalho de lavar em Happy Hour",
	whatWeOffer: "O que oferecemos",
	games: "Jogos",
	snacks: "Snacks",
	kidsSpace: "Espaço Kids",
	coworkingMessage:
		"Enquanto finaliza seu trabalho, lave suas roupas em um espaço confortável. Oferecemos serviços de impressão a preço de custo para você não perder tempo com isso.",
	ourLocation: "Nossa localização",
	day: "Dia",
	days: "Dias",
	contact: "Contato",
	doubtsMessage:
		"Dúvidas ou mais informações? É só falar com a gente agora mesmo!",
	period: "Período",
	deliveryType: "Forma de entrega",
	devolution: "Devolução",
	storeWithdraw: "Retirar na loja",
	delivery: "Entregar",
	seeDetails: "Ver detalhes",
	needToChooseAProductBefore: "Você precia escolher um produto para continuar",
	product: "Produto",
	unitPrice: "Preço Unitário",
	amount: "Quantidade",
	noneProductSelected: "Nenhum produto selecionado",
	totalPerDay: "Total por dia",
	mountBed: "Montagem de cama",
	close: "Fechar",
	discount: "Desconto",
	saveQuoteInEmail: "Salvar cotação no e-mail",
	requestAReservation: "Solicitar Reserva",
	additionalServices: "Serviços adicionais",
	importantInformations: "Informações importantes",
	receivingAtHome: "Receber em casa",
	duringBusinessHours: "Durante horário comercial",
	specialDeliveryMessage:
		"Entregas e/ou devoluções em horários especiais, entre em contato conosco.",
	freeChangeMessage:
		"A partir de 8(oito) diárias, oferecemos uma troca de enxoval grátis",
	moreChangeMessage:
		"Solicitações de mais trocas durante a estadia, entre em contato conosco.",
	"Lençol Queen": "Lençol Queen",
	"Lençol Solteiro": "Lençol Solteiro",
	Fronha: "Fronha",
	"Toalha de Banho": "Toalha de Banho",
	"Manta Casal": "Manta Casal",
	"Toalha de Rosto": "Toalha de Rosto",
	"Toalha de Piso": "Toalha de Piso",
	"Manta Solteiro": "Manta Solteiro",
	name: "Nome",
	typeYourNumber: "Digite seu telefone",
	send: "Enviar",
	required: "Obrigatório",
	invalidPhone: "Telefone inválido",
	sendChatSuccess:
		"Sucesso, em breve um de nossos atendentes entrará em contato pelo telefone informado! Obrigado."
};

export default strings;
