import {
  SET_ORDER_CITY,
  SET_ORDER_PEOPLE,
  SET_ORDER_START_DATE,
  SET_ORDER_END_DATE,
  SET_ORDER_CART,
  SET_DATE_FOCUSED,
  CLEAR
} from "../constants";

import * as actions from "../actions/order";

const initialState = {
  city: "Itapema",
  people: 1,
  deliveryType: 0,
  deliveryCity: null,
  deliveryCityId: null,
  startDate: null,
  endDate: null,
  dateFocused: null,
  cart: [],
  checkIn: "",
  checkOut: "",
  isLoading: false,
  deliveryCities: []
};

export default function(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialState
      };
    }
    case CLEAR:
      return { ...initialState };
    case SET_ORDER_CITY:
      return { ...state, city: payload };
    case SET_ORDER_PEOPLE:
      return { ...state, people: payload };
    case SET_ORDER_START_DATE:
      return { ...state, startDate: payload };
    case SET_ORDER_END_DATE:
      return { ...state, endDate: payload };
    case SET_ORDER_CART:
      return { ...state, cart: payload };
    case SET_DATE_FOCUSED:
      return { ...state, dateFocused: payload };
    default:
      return state;
  }
}
