import LocalizedStrings from "react-localization";

import pt from "./pt";
import en from "./en";
import es from "./es";

const strings = new LocalizedStrings({
  pt,
  en,
  es
});

export default strings;
