import {
  SET_ORDER_CITY,
  SET_ORDER_PEOPLE,
  SET_ORDER_START_DATE,
  SET_ORDER_END_DATE,
  SET_ORDER_CART,
  SET_DATE_FOCUSED,
  CLEAR
} from "../constants";

export const SET_VALUE = "[ORDER] SET_VALUE";
export const CLEAR_VALUE = "[ORDER] CLEAR_VALUE";

export function setCity(payload) {
  return {
    type: SET_ORDER_CITY,
    payload
  };
}
export function setPeople(payload) {
  return {
    type: SET_ORDER_PEOPLE,
    payload
  };
}
export function setStartDate(payload) {
  return {
    type: SET_ORDER_START_DATE,
    payload
  };
}
export function setEndDate(payload) {
  return {
    type: SET_ORDER_END_DATE,
    payload
  };
}
export function setCart(payload) {
  return {
    type: SET_ORDER_CART,
    payload
  };
}
export function setDateFocused(payload) {
  return {
    type: SET_DATE_FOCUSED,
    payload
  };
}
export function clear() {
  return {
    type: CLEAR
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValues() {
  return {
    type: CLEAR_VALUE
  };
}
