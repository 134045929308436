export const SET_ORDER_CITY = "SET_ORDER_CITY";
export const SET_ORDER_PEOPLE = "SET_ORDER_PEOPLE";
export const SET_ORDER_START_DATE = "SET_ORDER_START_DATE";
export const SET_ORDER_END_DATE = "SET_ORDER_END_DATE";
export const SET_ORDER_CART = "SET_ORDER_CART";
export const SET_DATE_FOCUSED = "SET_DATE_FOCUSED";

export const CLEAR = "CLEAR";

export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";
export const SET_WINDOW_SCROLL = "SET_WINDOW_SCROLL";
