const strings = {
  brand: "emKasa",
  homeTitle: "¡Tenemos lo que usted necesita para sentirse enKasa!",
  aboutUs: "Quienes somos",
  partnerButton: "Broker, conviértase en socio emKasa",
  rentalTitleMessage: "Alquiler de ropa de cama y baño para la temporada",
  city: "Ciudad",
  guests: "Huéspedes",
  chooseProducts: "Elegir Productos",
  arrival: "Llegada",
  output: "Salida",
  reserveOnlinePopoverTitle: "Reserve en línea y garantizar su descuento",
  howTheRentalWorksPopoverTitle: "Cómo funciona el alquiler",
  ourServicesPopoverTitle: "Nuestros servicios",
  ourProductsPopoverTitle: "Nuestros productos",
  ourMachinesPopoverTitle: "Nuestras máquinas",
  reserveOnlinePopoverLine01:
    "A partir de 7 diarias, gana 10% de descuento en los productos",
  reserveOnlinePopoverLine02:
    "A partir de 10 diarias, gane 15% de descuento en los productos",
  reserveOnlinePopoverLine03:
    "A partir de 15 diarias, entre en contacto con nosotros por el chat o teléfono",
  howTheRentalWorksPopoverLine01:
    "Por medio de un sistema de alquiler, la emKasa ofrece sábanas de todos los tamaños, almohadas, mantas, toallas de rostro y de baño y toalla de piso.",
  howTheRentalWorksPopoverLine02:
    "Todos nuestros productos son de alto estándar de calidad, Todo higienizado y con ese olor de calidez!",
  ourServicesPopoverLine01: "Alquiler de sábanas",
  ourServicesPopoverLine02: "Lavandería Self + Lounge",
  ourServicesPopoverLine03: "Coworking",
  ourServicesPopoverLine04: "Impresión",
  ourServicesPopoverLine05: "Internet y juegos",
  ourProductsPopoverLine01:
    "La emkasa cuenta con lo que hay de mejor en el mercado en relación a la calidad hotelera, nuestro ajuar de cama y baño son de la línea Hoteltex de Altenburg. Las toallas son un baño de encanto, que se destacan por la calidad del hilo retorcido, resultando en belleza y funcionalidad para valorar uno de los momentos más relajantes del día. Las sábanas, hacen una invitación para el sueño, con sus 180 hilos, siendo 50% algodón y 50% poliéster. Todos altamente higienizados, y con ese olor de confort que usted merece.",
  ourMachinesPopoverLine01:
    "Para higienizar las ropas de cama y baño y dejar con ese toque de macies y calidez que usted merece la emKasa utiliza equipos con Tecnología Maltec. Equipos que pueden realizar lavados a nivel de alta suciedad con procesos extremadamente eficientes, con un consumo reducido de agua, producto químico y energía eléctrica lo que hace nuestro servicio amigo del medio ambiente.",
  laundryTitle: "La Lavandería Self",
  laundrySubtitle: "Lavandería + Lounge + Coworking",
  priceTable: "Tabla de precios",
  justDry: "Sólo secar",
  justWash: "Sólo lavar",
  weDoItForYou: "Nosotros hacemos por usted",
  specialPricesForStudentsMessage:
    "precios especiales para los estudiantes, los mensajeros y las promociones ... consulte con nosotros.",
  happyHourTitle: "Transforme el trabajo de lavado en Happy Hour",
  whatWeOffer: "Lo que ofrecemos",
  games: "Juegos",
  snacks: "Aperitivos",
  kidsSpace: "Espacio Kids",
  coworkingMessage:
    "Mientras finaliza su trabajo, lave su ropa en un espacio cómodo. Ofrecemos servicios de impresión a precio de costo para que usted no pierda tiempo con eso.",
  ourLocation: "Nuestra ubicación",
  day: "Día",
  days: "Días",
  contact: "Contacto",
  doubtsMessage:
    "¿Dudas o más información? ¡Es sólo hablar con nosotros ahora mismo!",
  period: "Período",
  deliveryType: "Forma de entrega",
  devolution: "Devolución",
  storeWithdraw: "Retirar en la tienda",
  delivery: "Entregar",
  seeDetails: "Ver detalles",
  needToChooseAProductBefore:
    "Usted necesita elegir un producto para continuar",
  product: "Producto",
  unitPrice: "Precio unitario",
  amount: "Cantidad",
  noneProductSelected: "Ningún producto seleccionado",
  totalPerDay: "Total por día",
  mountBed: "Montaje de cama",
  close: "Cerca",
  discount: "Descuento",
  saveQuoteInEmail: "Guardar cotización en el Email",
  requestAReservation: "Solicitar Reserva",
  additionalServices: "Servicios adicionales",
  importantInformations: "Informaciones importantes",
  receivingAtHome: "Recibir en casa",
  duringBusinessHours: "Durante el horario comercial",
  specialDeliveryMessage:
    "Entregas y/o devoluciones en horarios especiales, entre en contacto con nosotros.",
  freeChangeMessage:
    "A partir de 8 (ocho) diarias, ofrecemos un intercambio de ajusto gratis",
  moreChangeMessage:
    "Solicitudes de más cambios durante la estancia, entre en contacto con nosotros.",
  "Lençol Queen": "Sábana ...",
  "Lençol Solteiro": "Sábana ..",
  Fronha: "Funda de Almohada",
  "Toalha de Banho": "Toalla de baño",
  "Manta Casal": "Manta Casal",
  "Toalha de Rosto": "Toalla de rostro",
  "Toalha de Piso": "Toalla de Piso",
  "Manta Solteiro": "Manta Soltero",
  name: "Nombre",
  typeYourNumber: "Ingrese su teléfono",
  send: "Enviar"
};

export default strings;
