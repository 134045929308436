import React, { useState } from "react";
import "./styles.css";

import ls from "../../Localization";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/dist/style.css";
import { isValidNumber } from "libphonenumber-js";
import { ClipLoader } from "react-spinners";
import SweetAlert from "react-bootstrap-sweetalert";
import { sendPost } from "../../services/api";

import { IoMdOpen } from "react-icons/io";

let lang = require(`../Order/lang/pt.json`);

function Chat() {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState(null);

	const handleSubmit = e => {
		e.preventDefault();

		let newErrors = {};
		if (!name) newErrors.name = ls.required;
		if (!phone) newErrors.phone = ls.required;
		else if (!isValidNumber(phone)) newErrors.phone = ls.invalidPhone;

		setErrors(newErrors);

		if (Object.keys(newErrors).length > 0) return;

		setLoading(true);

		sendPost(
			"/Chat",
			{ name, phone },
			{
				key:
					"emKasa_whatspro_WKdvvY6BUGDjQ6YAScppTn332aJY57LnBZXT5wfejacCe22v39QmYLDykmqFP6Nh"
			}
		)
			.then(res => {
				if (res.ok) return res.ok;

				return null;
			})
			.then(json => {
				if (json) {
					setAlert(
						<SweetAlert
							success
							title=""
							style={{ display: "block" }}
							onConfirm={() => setAlert(null)}
							confirmBtnText="Fechar"
						>
							{ls.sendChatSuccess}
						</SweetAlert>
					);

					setName("");
					setPhone("");
					setOpen(false);
				} else {
					setAlert(
						<SweetAlert
							warning
							title=""
							style={{ display: "block" }}
							onConfirm={() => setAlert(null)}
							confirmBtnText="Fechar"
						>
							Ocorreu um erro ao tentar enviar sua requisição, por favor entre
							em contato com nosso atendimento{" "}
							<a
								target="_blank"
								href={`https://wa.me/5547992435520?text=Olá, me chamo ${name} e estou entrando em contato através do site`}
							>
								clicando aqui
							</a>
						</SweetAlert>
					);
				}

				setLoading(false);
			});
	};

	return (
		<div>
			<div className={`chatbubble ${open ? "opened" : ""}`}>
				<div className="unexpanded" onClick={() => setOpen(prev => !prev)}>
					<div className="title">Converse pelo WhatsApp</div>
				</div>
				<div className="expanded chat-window">
					<div className="login-screen active">
						<form id="loginScreenForm" onSubmit={handleSubmit} noValidate>
							<div className={`form-group ${errors.name ? "has-error" : ""}`}>
								<input
									type="text"
									value={name}
									className="form-control"
									id="fullname"
									placeholder={ls.name + "*"}
									onChange={v => setName(v.target.value)}
									required
								/>
								<p className="text-danger">{errors.name}</p>
							</div>
							<div className={`form-group ${errors.phone ? "has-error" : ""}`}>
								<PhoneInput
									placeholder={ls.typeYourNumber}
									value={phone}
									preferredCountries={["br", "ar", "py", "uy"]}
									defaultCountry="br"
									localization={lang}
									required
									onChange={v => setPhone(v)}
									inputStyle={{
										width: 330,
										borderColor: errors.phone ? "#a94442" : undefined
									}}
								/>
								<p className="text-danger">{errors.phone}</p>
							</div>
							<button
								type="submit"
								disabled={loading}
								className="btn btn-block btn-primary"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{ls.send}
								{loading && (
									<ClipLoader
										color={"#f79563"}
										css={{ marginLeft: 8 }}
										loading
										size={16}
									/>
								)}
							</button>
							<div
								style={{
									display: "flex",
									alignItems: "flex-end",
									justifyContent: "flex-end",
									padding: 10,
									height: 124
								}}
							>
								<a
									target="_blank"
									href={`https://wa.me/5547992435520?text=Olá, ${
										name ? `me chamo ${name} e ` : ""
									} estou entrando em contato através do site`}
									style={{ display: "flex", alignItems: "center" }}
								>
									Abrir WhatsApp <IoMdOpen style={{ marginLeft: 1 }} />
								</a>
							</div>
						</form>
					</div>
				</div>
			</div>
			{alert}
		</div>
	);
}

export default Chat;
